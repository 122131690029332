@tailwind base;
@tailwind components;

@import "common/common";

// global styles on the most common elements
html {
    @apply font-sans font-light leading-normal text-gray-800;
}

a, .button-link {
    @apply text-blue-500 no-underline;
    &:hover, &:active {
        @apply underline text-blue-700;
    }
}

.page-container {
    @apply relative pt-8 pb-24 min-h-screen bg-gray-800;
    background-image: url('/images/bkg.png');
}

.content-wrap {
    @apply max-w-11/12 h-full mx-auto p-8 bg-white;
    @screen md {
        @apply max-w-3xl;
    }
}

.button-link {
    @apply font-light;
}

// common styles
@import "common/alerts",
    "common/content_alignment",
    "common/headings",
    "common/forms",
    "common/modal",
    "common/loading";

// public only styles
@import "public/buttons",
    "public/headings",
    "public/header",
    "public/content",
    "public/schedule";

// page specific
//@import ;

@tailwind utilities;
