.button {
    @apply py-2 px-6 bg-gray-300;
    &:hover, &:focus {
        @apply bg-gray-400;
    }
}

.button-green {
    @apply inline-block relative mb-2 py-2 px-6 text-xl font-yanone font-extralight tracking-wider
        bg-green-flc text-white text-center no-underline;
    box-shadow: 4px 4px 0 rgba(25,25,25,0.1);
    &:hover, &:focus {
        @apply top-px left-px no-underline text-white;
        box-shadow: 2px 2px 0 rgba(25,25,25,0.1);
    }
}

.button-critical {
    @apply text-white bg-red-500;
    &:hover, &:focus {
        @apply bg-red-600;
    }
    //&:disabled {
    //    @apply bg-white border-gray-400;
    //    &:hover {
    //        @apply text-red-600 bg-white;
    //    }
    //}
}
